<template>
  <MainPage/>
</template>

<script>
import MainPage from "@/components/MainPage.vue";

export default {
  name: 'App',
  components: {
    MainPage
  }
}
</script>
