<template>
   <div class="container section">
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <h1 class="title"><b>Whiter.tech</b></h1>
            <h2 class="subtitle">Generate your website</h2>
            <div class="content is-small">
              <div class="image-text-container">
                <img src="@/assets/telegram.png" width="16" height="16" alt="https://telegram.me/web_gen_ai"/>
                <a href="https://telegram.me/web_gen_ai" target="_blank">Channel</a>
              </div>
            </div>

            <hr>

              <div class="field" v-show="!projectId.length > 0">
                <label class="label" for="otp-code">Business name (min 10). There {{businessName.length}}</label>
                <div class="control">
                  <input class="input" type="text"
                         v-model="businessName"
                         placeholder="Enter your business name">
                </div>
              </div>

              <div class="field" v-show="!projectId.length > 0">
                <label class="label" for="otp-code">Business description (min 50). There {{businessDescription.length}}</label>
                <div class="control">
                  <textarea class="textarea"
                            v-model="businessDescription"
                            placeholder="Enter your business description">
                  </textarea>
                </div>
              </div>

              <div class="field" v-show="!projectId.length > 0">
                <label class="label">Choose template</label>
<!--                <p class="help">click for review</p>-->
                <div class="columns">
                  <div class="column">
                    <label>
                      <input type="radio" name="template-selection" value="template1" checked="checked">
                      <img src="@/assets/template-1-256.jpg"
                           alt="Template 1" class="template-option"
                           width="128">
                      <div>
                        <a href="https://api.whiter.tech/v1/project/1ac34f06-5c05-491d-bcd5-5b448fc14be8/file?path=index.html"
                        target="_blank">Example</a>
                      </div>
                    </label>
                  </div>
<!--                  <div class="column">-->
<!--                    <label>-->
<!--                      <input type="radio" name="template-selection" value="template2">-->
<!--                      <img src="https://via.placeholder.com/150x100?text=Template+2" alt="Template 2" class="template-option">-->
<!--                      <div class="template-label">Template 2</div>-->
<!--                    </label>-->
<!--                  </div>-->
              </div>
              </div>

            <div class="field" v-show="!projectId.length > 0">
              <label class="label" for="otp-code">What language will the site be in?</label>
              <div class="control">
                <input class="input" type="text"
                       v-model="language"
                       placeholder="Enter language">
              </div>
            </div>

              <div class="field" v-show="!projectId.length > 0">
                <label class="label" for="otp-code">OTP Key</label>
                <div class="control">
                  <input class="input" type="text"
                         v-model="otpKey"
                         placeholder="Enter your otp key">
<!--                  <p class="help">OTP code costs 200 rubles<br></p>-->
                  <div class="image-text-container help">
                    To buy, write to administrators:&nbsp;
                    <img src="@/assets/telegram.png" width="16" height="16"/>
                    <a href="https://telegram.me/traffnotdead" target="_blank">traffnotdead</a>
                    &nbsp;or&nbsp;
                    <img src="@/assets/telegram.png" width="16" height="16"/>
                    <a href="https://telegram.me/webgenai" target="_blank">webgenai</a>
                  </div>
                </div>
              </div>

              <div class="field" v-show="!projectId.length > 0">
                <div class="control">
                  <button class="button is-primary is-fullwidth"
                          type="submit"
                          @click="generate()"
                          :disabled="businessName.length < 10 ||
              businessDescription.length < 50 ||
              !otpKey.length > 0 ||
              !language.length > 0 ||
              !wsConnected"
                          v-show="!generatingHtml">Generate
                  </button>

                  <button class="button is-warning is-fullwidth"
                          v-show="generatingHtml"
                          :disabled="true">Wait generating... Don't close the tab!
                  </button>
                  <br>

                  <progress class="progress is-small" v-show="generatingHtml"
                            :value="progressValue" max="100"></progress>

                </div>
              </div>

            <div class="field">
              <span class="tag is-danger" v-show="restError.length > 0">{{ restError }}</span>
              <span class="tag is-danger" v-show="genError.length > 0">Generation error, contact administrator!</span>
              <span class="tag is-danger" v-show="!wsConnected">Technical problems. Try again later...</span>
            </div>

              <div class="field" v-if="projectLink">
<!--                Temp view:<a :href="projectLink" target="_blank">{{ projectLink }}</a>-->
                <p>If the archive is not downloaded automatically, click on the button</p>
                <button class="button is-success is-fullwidth" @click="download()">Download site as zip</button>
              </div>

          </div>
        </div>
      </div>

    </div>
</template>

<script>
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import {BASE_URL} from "@/assets/http-common";
import {HTTP} from "@/assets/http-common";

export default {

  name: 'MainPage',
  data() {
    return {

      otpKey: '',
      businessName: '',
      businessDescription: '',
      language: '',

      projectId: '',
      projectLink: '',
      generatingHtml: false,

      wsConnected: false,
      wsUserName: null,

      //progress bar
      progressValue: 0,
      totalTime: 120, //2min
      elapsedTime: 0,
      interval: null,

      restError: '',
      genError: ''
    }
  },

  methods: {

    generate() {
      this.restError = '';

      HTTP.post('/v1/project',
          {
            otpKey: this.otpKey,
            wsUserName: this.wsUserName,
            templateId: '7f098493-0a8a-4811-8c27-6cf3e0eab72e',
            businessName: this.businessName,
            businessDescription: this.businessDescription,
            language: this.language,
          })
          .catch(error => {
            this.restError = error.response.data.message;
            if (this.restError.length <= 0) {
              this.restError = 'Technical problems. Try again later...'
            }
            this.generatingHtml = false;
          });
      this.generatingHtml = true;
      this.startProgressBar();
    },

    connect() {
      let self = this;
      let socket = new SockJS(BASE_URL + '/ws-stomp');

      this.stompClient = Stomp.over(socket);
      this.stompClient.debug = () => {
      };
      this.stompClient.connect(
          {},
          frame => {
            this.wsConnected = true;
            this.wsUserName = JSON.parse(JSON.stringify(frame))['headers']['user-name'];
            console.log('wsUserName = ' + this.wsUserName)

            this.stompClient.subscribe('/user/topic/gen/done', tick => {
              self.receiveProjectId(tick.body);
              this.generatingHtml = false;
            });

            this.stompClient.subscribe('/user/topic/gen/error', tick => {
              self.genError = tick.body;
              this.generatingHtml = false;
            });

          }
      );
    },

    receiveProjectId(projectId) {
      this.projectId = projectId;
      this.projectLink = BASE_URL + '/v1/project/' + projectId + '/file?path=index.html'
      this.download();
    },

    download() {
      HTTP.get('/v1/project/' + this.projectId + '/download/zip',
          {
            responseType: 'blob',
          })
          .then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            let downloadFileName = 'archive.zip'; // Default to requested file name

            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
              if (fileNameMatch.length === 2) {
                downloadFileName = fileNameMatch[1];
              }
            }
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', downloadFileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
    },

    startProgressBar() {
      if (this.interval) {
        clearInterval(this.interval);
        this.elapsedTime = 0;
        this.progressValue = 0;
      }

      // Запускаем новый интервал
      this.interval = setInterval(() => {
        if (this.elapsedTime < this.totalTime) {
          this.elapsedTime++;
          this.progressValue = (this.elapsedTime / this.totalTime) * 100;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },

  },

  created() {
    this.connect();
  }

}
</script>

<style>
.template-option {
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.template-option:hover {
  border-color: #3273dc;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + .template-option {
  border-color: #3273dc;
}

.template-label {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 5px;
  color: #555;
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

body {
  margin: 0;
  height: 100%;
  background: linear-gradient(270deg, #9396a3, #b3c2d6, #b1c6f1);
  background-size: 600% 600%;
  animation: gradientShift 10s ease infinite;
}

.box {
  background: white;
}

.image-text-container {
  display: flex;
  align-items: center;
}

.image-text-container img {
  margin-right: 2px;
}

</style>